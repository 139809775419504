// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-additional-info-js": () => import("./../../../src/templates/additional_info.js" /* webpackChunkName: "component---src-templates-additional-info-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-static-site-js": () => import("./../../../src/templates/static_site.js" /* webpackChunkName: "component---src-templates-static-site-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/thank_you.js" /* webpackChunkName: "component---src-templates-thank-you-js" */)
}

